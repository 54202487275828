import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private _alert = signal<{
    message: string;
    bgColor: 'red' | 'green';
    type: 'ex' | 'en';
    isVisible: boolean;
  }>({
    message: '',
    bgColor: 'green',
    type: 'ex',
    isVisible: false,
  });

  get alert() {
    return this._alert.asReadonly();
  }

  showAlert(message: string, bgColor: 'red' | 'green' = 'green') {
    this._alert.set({
      message,
      bgColor,
      type: 'en',
      isVisible: true,
    });

    setTimeout(() => {
      this.hideAlert();
    }, 5000);
  }

  hideAlert() {
    this._alert.set({
      message: '',
      bgColor: 'green',
      type: 'ex',
      isVisible: false,
    });
  }
}
